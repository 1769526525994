import {AfterViewInit, Component, Input} from "@angular/core";
import {Chart, ChartDataset} from "chart.js";
import {BehaviorSubject} from "rxjs";
import {Objects} from "../../tools/objects";
import {LiveChart} from "../../models/live-chart";
import {v4 as uuidv4} from "uuid";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit {

  private chartData: BehaviorSubject<LiveChart> = new BehaviorSubject<LiveChart>(ChartComponent.empty());
  protected chart?: Chart;
  protected chartId: string = 'chart-' + uuidv4();

  @Input() set data(chart: LiveChart | undefined) {
    if (!chart || Objects.isEqual(chart, this.chartData.getValue())) {
      return;
    }
    this.chartData.next(chart);
  }

  ngAfterViewInit(): void {
    this.chartData.subscribe(chartData => {
      this.destroyChart();
      if (chartData) {
        setTimeout(() => this.createChart(chartData));
      }
    });
  }

  private destroyChart(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  private createChart(chartData: LiveChart): void {
    const canvas = <HTMLCanvasElement> document.getElementById(this.chartId);
    this.chart = new Chart(canvas, {
      type: 'line',
      data: {
        labels: chartData.labels,
        datasets: chartData.datasets.map(dataset =>
          <ChartDataset>{
            label: dataset.label,
            data: dataset.data,
            backgroundColor: dataset.color,
            borderColor: dataset.color,
            borderWidth: 0.7,
            radius: 1,
            fill: false,
          })
      },
      options: {
        layout: {
          padding: 5,
        },
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            labels: {
              color: '#7da8b2',
            }
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#7da8b2',
            },
            grid: {
              color: '#3a5359',
            }
          },
          y: {
            ticks: {
              color: '#7da8b2'
            },
            grid: {
              color: '#3a5359',
            }
          }
        }
      }
    });
  }

  public static empty(): LiveChart {
    return {
      labels: [],
      datasets: []
    };
  }
}
