import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild
} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin} from "rxjs";
import {TeamService} from "../../services/team.service";
import {SkaterService} from "../../services/skater.service";
import {GameService} from "../../services/game.service";
import {JamService} from "../../services/jam.service";
import {NotificationService} from "../../services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {JamsState} from "./jams.state";
import {RouterTools} from "../../tools/router.tools";
import {Message} from "../../models/message";
import {ColorTools} from "../../tools/color.tools";
import {Skater} from "../../models/skater";
import {Jam} from "../../models/jam";
import {JamExtraData} from "../../models/jam-extra-data";
import {TrackSegment} from "../../models/track-segment";
import {TeamLineService} from "../../services/team-line.service";
import {TeamLine} from "../../models/team-line";
import {PredictionTools} from "../../tools/predictionTools";
import {SelectInfo} from "../../models/select-info";
import {Objects} from "../../tools/objects";
import {DialogOptions} from "../../models/dialog-options";
import {DialogService} from "../../services/dialog.service";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-jams',
  templateUrl: './jams.component.html',
  styleUrls: ['./jams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JamsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('jamList') jamList!: ElementRef;

  readonly periodIndices = [0, 1];
  readonly penaltyCounts = [0, 1, 2, 3, 4];
  refreshTimeoutReference: any;
  refreshTimeout = 1000;

  state$: StateSubject<JamsState> = new StateSubject<JamsState>(JamsState.create());
  backgroundColors$: Signal<string[]>;
  jamIndices$: Signal<number[][]>;
  lineOptions$: Signal<TeamLine[][]>;
  jammerOptions$: Signal<Skater[][]>;
  pivotOptions$: Signal<Skater[][]>;
  blockerOptions$: Signal<Skater[][]>;
  leads$: Signal<(number | undefined)[][]>;
  team1Jammers$: Signal<(Skater | undefined)[][]>;
  team2Jammers$: Signal<(Skater | undefined)[][]>;
  blockers$: Signal<(Skater | undefined)[][][][]>; // periodIndex, jamIndex, teamIndex, blockerIndex
  team1Line$: Signal<(TeamLine | undefined)[][]>;
  team2Line$: Signal<(TeamLine | undefined)[][]>;
  team1StarPasses$: Signal<boolean[][]>;
  team2StarPasses$: Signal<boolean[][]>;
  team1Scores$: Signal<number[][]>;
  team2Scores$: Signal<number[][]>;
  team1Penalties$: Signal<number[][]>;
  team2Penalties$: Signal<number[][]>;
  skaterHighlights$: Signal<(Skater | undefined)[][][]>;
  lineHighlight$: Signal<(TeamLine | undefined)[][]>;
  showPivot$: Signal<boolean[]>;
  canEdit$: Signal<boolean>;
  canEditJams$: Signal<boolean>;
  periodScores$: Signal<number[][]>;
  totalScores$: Signal<number[]>;
  isFinished$: Signal<boolean>;
  trackWidth$: Signal<number>;

  constructor(private teamService: TeamService,
              private teamLineService: TeamLineService,
              private skaterService: SkaterService,
              private gameService: GameService,
              private jamService: JamService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private pageService: PageService,
              private route: ActivatedRoute,
              private router: Router) {
    this.showPivot$ = this.state$.signal(state => state.data.showPivot);
    this.canEdit$ = this.state$.signal(state => state.canEdit);
    this.canEditJams$ = this.state$.signal(state => state.canEditJams);
    this.periodScores$ = this.state$.signal(state => state.data.periodScores);
    this.isFinished$ = this.state$.signal(state => !!state.data.game?.isFinished);
    this.trackWidth$ = this.state$.signal(state => state.data.trackWidth);
    this.totalScores$ = this.state$.signal(state => [
      state.data.periodScores[0][0] + state.data.periodScores[0][1],
      state.data.periodScores[1][0] + state.data.periodScores[1][1]
    ]);
    this.state$.get(state => ({
      team1Name: state.team1Name,
      team2Name: state.team2Name,
      game: state.data.game,
      autoRefresh: state.data.autoRefresh,
      showPivot: state.data.showPivot,
      smartCompletion: state.data.smartCompletion
    })).subscribe(data => {
      this.pageService.invoke(page => page.reset(data.team1Name + ' vs ' + data.team2Name)
        .withBackButton(() => this.goToGames())
        .withActions([
          {
            text: 'Auto Refresh',
            icon: 'refresh_auto',
            visible: true,
            enabled: true,
            primary: false,
            active: data.autoRefresh,
            onClick: () => this.toggleAutoRefresh()
          },
          {
            text: data.game?.isLive ? 'Live' : 'Go Live',
            icon: 'live',
            visible: true,
            enabled: true,
            primary: false,
            active: !!data.game?.isLive,
            onClick: () => this.toggleLiveStatistics()
          },
          {
            text: 'Auto Complete',
            icon: 'magic',
            visible: true,
            enabled: true,
            primary: false,
            active: data.smartCompletion,
            onClick: () => this.toggleSmartCompletion()
          }
        ])
        .withTabs(Tabs.of([
          Tab.of('Info', true, () => this.goToGame()),
          Tab.of('Jams'),
          Tab.of('Stats', Objects.isNotNull(data.game?.id), () => this.goToGameStats()),
        ], 1)))
    });
    this.backgroundColors$ = this.state$.getSignal(state => state.data.game, game => {
      const color1 = game?.teamColor1;
      const color2 = game?.teamColor2;
      return [ColorTools.toHex(color1), ColorTools.toHex(color2)];
    });
    this.jamIndices$ = this.state$.getSignal(state => state.data.jams, jams => {
      let period1 = [];
      let period2 = [];
      for (let i = 0; i < jams.length; i++) {
        const jam = jams[i];
        if (jam.period === 1) {
          period1.push(jam.jamNumber - 1);
        } else if (jam.period === 2) {
          period2.push(jam.jamNumber - 1);
        }
      }
      return [period1, period2];
    });
    this.lineOptions$ = this.state$.getSignal(state => ({
      lines1: state.data.lines1,
      lines2: state.data.lines2
    }), data => {
      const lines1 = data.lines1;
      const lines2 = data.lines2;
      return [lines1, lines2];
    });
    this.jammerOptions$ = this.state$.getSignal(state => ({
      skaters1: state.data.skaters1,
      skaters2: state.data.skaters2
    }), data => {
      const skaters1 = data.skaters1;
      const skaters2 = data.skaters2;
      const jammers1 = skaters1.filter(skater => skater.isJammer);
      const jammers2 = skaters2.filter(skater => skater.isJammer);
      return [jammers1, jammers2];
    });
    this.pivotOptions$ = this.state$.getSignal(state => ({
      skaters1: state.data.skaters1,
      skaters2: state.data.skaters2
    }), data => {
      const skaters1 = data.skaters1;
      const skaters2 = data.skaters2;
      const pivots1 = skaters1.filter(skater => skater.isPivot);
      const pivots2 = skaters2.filter(skater => skater.isPivot);
      return [pivots1, pivots2];
    });
    this.blockerOptions$ = this.state$.getSignal(state => ({
      skaters1: state.data.skaters1,
      skaters2: state.data.skaters2
    }), data => {
      const skaters1 = data.skaters1;
      const skaters2 = data.skaters2;
      return [skaters1, skaters2];
    });
    this.leads$ = this.state$.getSignal(state => state.data.jams, jams => {
      const leads1 = jams.filter(jam => jam.period === 1).map(jam => jam.lead);
      const leads2 = jams.filter(jam => jam.period === 2).map(jam => jam.lead);
      return [leads1, leads2];
    });
    this.team1Jammers$ = this.state$.getSignal(state => ({
      jams: state.data.jams,
      skaters1: state.data.skaters1
    }), (data) => {
      const jams = data.jams;
      const skaters1 = data.skaters1;
      const jammers1 = jams.filter(jam => jam.period === 1).map(jam => skaters1.find(skater => skater.id === jam.team1JammerId));
      const jammers2 = jams.filter(jam => jam.period === 2).map(jam => skaters1.find(skater => skater.id === jam.team1JammerId));
      return [jammers1, jammers2];
    });
    this.team2Jammers$ = this.state$.getSignal(state => ({
      jams: state.data.jams,
      skaters2: state.data.skaters2
    }), (data) => {
      const jams = data.jams;
      const skaters2 = data.skaters2;
      const jammers1 = jams.filter(jam => jam.period === 1).map(jam => skaters2.find(skater => skater.id === jam.team2JammerId));
      const jammers2 = jams.filter(jam => jam.period === 2).map(jam => skaters2.find(skater => skater.id === jam.team2JammerId));
      return [jammers1, jammers2];
    });
    this.team1Line$ = this.state$.getSignal(state => ({jams: state.data.jams, lines1: state.data.lines1}), (data) => {
      const jams = data.jams;
      const lines1 = data.lines1;
      const line1 = jams.filter(jam => jam.period === 1).map(jam => lines1.find(line => line.id === jam.team1LineId));
      const line2 = jams.filter(jam => jam.period === 2).map(jam => lines1.find(skater => skater.id === jam.team1LineId));
      return [line1, line2];
    });
    this.team2Line$ = this.state$.getSignal(state => ({jams: state.data.jams, lines2: state.data.lines2}), (data) => {
      const jams = data.jams;
      const lines2 = data.lines2;
      const line1 = jams.filter(jam => jam.period === 1).map(jam => lines2.find(skater => skater.id === jam.team2LineId));
      const line2 = jams.filter(jam => jam.period === 2).map(jam => lines2.find(skater => skater.id === jam.team2LineId));
      return [line1, line2];
    });
    this.blockers$ = this.state$.getSignal(state => ({
      jams: state.data.jams,
      skaters1: state.data.skaters1,
      skaters2: state.data.skaters2
    }), (data) => {
      const jams = data.jams;
      const skaters1 = data.skaters1;
      const skaters2 = data.skaters2;
      const period1 = [];
      const period2 = [];
      for (let i = 0; i < jams.length; i++) {
        const jam = jams[i];
        const blockers1 = [
          skaters1.find(skater => skater.id === jam.team1PivotId),
          skaters1.find(skater => skater.id === jam.team1Blocker1Id),
          skaters1.find(skater => skater.id === jam.team1Blocker2Id),
          skaters1.find(skater => skater.id === jam.team1Blocker3Id),
        ];
        const blockers2 = [
          skaters2.find(skater => skater.id === jam.team2PivotId),
          skaters2.find(skater => skater.id === jam.team2Blocker1Id),
          skaters2.find(skater => skater.id === jam.team2Blocker2Id),
          skaters2.find(skater => skater.id === jam.team2Blocker3Id),
        ];
        if (jams[i].period === 1) {
          period1.push([blockers1, blockers2]);
        } else {
          period2.push([blockers1, blockers2]);
        }
      }
      return [period1, period2];
    });
    this.team1StarPasses$ = this.state$.getSignal(state => state.data.jams, jams => {
      const starPasses1 = jams.filter(jam => jam.period === 1).map(jam => !!jam.team1StarPass);
      const starPasses2 = jams.filter(jam => jam.period === 2).map(jam => !!jam.team1StarPass);
      return [starPasses1, starPasses2];
    });
    this.team2StarPasses$ = this.state$.getSignal(state => state.data.jams, jams => {
      const starPasses1 = jams.filter(jam => jam.period === 1).map(jam => !!jam.team2StarPass);
      const starPasses2 = jams.filter(jam => jam.period === 2).map(jam => !!jam.team2StarPass);
      return [starPasses1, starPasses2];
    });
    this.team1Scores$ = this.state$.getSignal(state => state.data.jams, jams => {
      const scores1 = jams.filter(jam => jam.period === 1).map(jam => jam.team1Score || 0);
      const scores2 = jams.filter(jam => jam.period === 2).map(jam => jam.team1Score || 0);
      return [scores1, scores2];
    });
    this.team2Scores$ = this.state$.getSignal(state => state.data.jams, jams => {
      const scores1 = jams.filter(jam => jam.period === 1).map(jam => jam.team2Score || 0);
      const scores2 = jams.filter(jam => jam.period === 2).map(jam => jam.team2Score || 0);
      return [scores1, scores2];
    });
    this.team1Penalties$ = this.state$.getSignal(state => ({
      jams: state.data.jams,
      skaters1: state.data.skaters1,
      jamExtraDatas: state.data.jamExtraDatas
    }), data => {
      const jams = data.jams;
      const skaters = data.skaters1;
      const jamExtraDatas = data.jamExtraDatas;
      let penalties1 = [];
      let penalties2 = [];
      for (let i = 0; i < jams.length; i++) {
        const jam = jams[i];
        const jamExtraData = jamExtraDatas[i];
        const penalties = jamExtraData.penalties.filter(penalty => skaters.find(skater => skater.id === penalty.skaterId));
        if (jam.period === 1) {
          penalties1.push(penalties.length);
        } else {
          penalties2.push(penalties.length);
        }
      }
      return [penalties1, penalties2];
    });
    this.team2Penalties$ = this.state$.getSignal(state => ({
      jams: state.data.jams,
      skaters2: state.data.skaters2,
      jamExtraDatas: state.data.jamExtraDatas
    }), data => {
      const jams = data.jams;
      const skaters = data.skaters2;
      const jamExtraDatas = data.jamExtraDatas;
      let penalties1 = [];
      let penalties2 = [];
      for (let i = 0; i < jams.length; i++) {
        const jam = jams[i];
        const jamExtraData = jamExtraDatas[i];
        const penalties = jamExtraData.penalties.filter(penalty => skaters.find(skater => skater.id === penalty.skaterId));
        if (jam.period === 1) {
          penalties1.push(penalties.length);
        } else {
          penalties2.push(penalties.length);
        }
      }
      return [penalties1, penalties2];
    });
    this.skaterHighlights$ = this.state$.signal(state => state.data.skaterHighlights);
    this.lineHighlight$ = this.state$.signal(state => state.data.lineHighlights);
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const id = RouterTools.toNumber(params, 'gameId');
      this.state$.invoke(state => state.withQueryId(id));
    });
    this.state$.get(state => '' + state.data.gameId).subscribe(() => {
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshTimeoutReference);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const jamListWidth = this.jamList.nativeElement.offsetWidth;
      this.state$.invoke(state => state.withTrackWidth((jamListWidth - 300) / 2));
    });
    this.refreshJams();
  }

  public toggleSmartCompletion(): void {
    this.state$.invoke(state => state.withSmartCompletion(!state.data.smartCompletion));
  }

  public toggleShowPivot(teamIndex: number): void {
    this.state$.invoke(state => state.withShowPivot(teamIndex, !state.data.showPivot[teamIndex]));
  }

  public toggleLiveStatistics(): void {
    const state = this.state$.getValue();
    const game = state.data.game;
    if (!game) {
      return;
    }

    if (!game.isLive) {
      this.doToggleLiveStatistics();
    } else {
      this.dialogService.show(DialogOptions
        .create('Stop Live Statistics', 'Are you sure you want to end live stats?')
        .withConfirm(() => this.doToggleLiveStatistics()));
    }
  }

  private doToggleLiveStatistics(): void {
    const state = this.state$.getValue();
    const game = state.data.game;
    if (!game) {
      return;
    }
    const updatedGame = {...game, isLive: !game.isLive};
    this.gameService.update(game.id, updatedGame).subscribe({
      next: game => {
        this.state$.invoke(state => state.refreshGame(game));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to toggle live statistics`));
      }
    });
  }

  public toggleGameFinished(): void {
    const state = this.state$.getValue();
    const game = state.data.game;
    if (!game) {
      return;
    }

    const updatedGame = {...game, isFinished: !game.isFinished};
    this.gameService.update(game.id, updatedGame).subscribe({
      next: game => {
        this.state$.invoke(state => state.refreshGame(game));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to toggle game finished`));
      }
    });
  }

  public gameId(): number | undefined {
    return this.state$.getValue().data.gameId;
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const gameId = state.data.gameId;
    if (!gameId) {
      this.state$.invoke(state => state.refreshGame(undefined));
      return;
    }

    this.gameService.oneById(gameId).subscribe({
      next: game => {
        this.state$.invoke(state => state.refreshGame(game));
        this.refreshJams();
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load game`));
      }
    });
  }

  public newJam(periodIndex: number): void {
    const state = this.state$.getValue();
    const gameId = state.data.gameId;
    if (!gameId) {
      return;
    }

    const jams = state.data.jams;
    const allLines = [state.data.lines1, state.data.lines2];
    const allSkaters = [state.data.skaters1, state.data.skaters2];
    let jamGuess = {};
    if (state.data.smartCompletion) {
      jamGuess = PredictionTools.predictNextJam(jams, allLines, allSkaters);
    }
    this.createOrUpdateJam({
      ...jamGuess,
      gameId: gameId,
      period: periodIndex + 1,
      jamNumber: state.getNewJamNumber(periodIndex + 1),
      team1Score: 0,
      team2Score: 0,
    });
  }

  public createOrUpdateJam(jam: Partial<Jam>): void {
    this.jamService.createOrUpdateJam(jam).subscribe({
      next: jam => {
        this.state$.invoke(state => state.refreshJam(jam));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to save jam`));
      }
    });
  }

  public deleteLastJam(periodIndex: number): void {
    this.dialogService.show(DialogOptions
      .create('Delete Jam', 'Are you sure you want to delete this jam?')
      .withConfirm(() => this.doDeleteLastJam(periodIndex)));
  }

  private doDeleteLastJam(periodIndex: number): void {
    const state = this.state$.getValue();
    const gameId = state.data.gameId;
    if (!gameId) {
      return;
    }

    const jam = state.getLastJam(periodIndex);
    if (!jam) {
      return;
    }

    this.jamService.deleteJam(gameId, jam.period, jam.jamNumber).subscribe({
      next: () => {
        this.state$.invoke(state => state.deleteJam(jam.period, jam.jamNumber));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to delete jam`));
      }
    });
  }

  public toggleAutoRefresh(): void {
    const state = this.state$.getValue();
    const autoRefresh = !state.data.autoRefresh;
    this.state$.invoke(state => state.withAutoRefresh(autoRefresh));
    if (autoRefresh) {
      this.refreshJams();
    }
  }

  private refreshJams(): void {
    this.clearRefreshTimeout();
    const state = this.state$.getValue();
    const game = state.data.game;
    if (!game) {
      return;
    }

    forkJoin([
      this.teamService.getTeam(game.teamId1),
      this.teamService.getTeam(game.teamId2),
      this.skaterService.getSkatersByTeamId(game.teamId1),
      this.skaterService.getSkatersByTeamId(game.teamId2),
      this.teamLineService.getTeamLinesByTeamId(game.teamId1),
      this.teamLineService.getTeamLinesByTeamId(game.teamId2),
      this.gameService.oneById(game.id),
      this.jamService.getJamByGameId(game.id),
      this.jamService.getActions()
    ]).subscribe({
      next: ([team1, team2, skaters1, skaters2, lines1, lines2, game, jams, actions]) => {
        this.state$.invoke(state => state.refresh(team1, team2, skaters1, skaters2, lines1, lines2, game, jams, actions));
        this.delayedRefreshPeriodically();
      },
      error: () => {
        console.error(`Failed to load jams`);
        this.delayedRefreshPeriodically();
      }
    });
  }

  private clearRefreshTimeout(): void {
    if (this.refreshTimeoutReference) {
      clearTimeout(this.refreshTimeoutReference);
      this.refreshTimeoutReference = undefined;
    }
  }

  private delayedRefreshPeriodically(): void {
    this.clearRefreshTimeout();
    const state = this.state$.getValue();
    if (state.data.autoRefresh) {
      console.debug('Scheduling auto refresh');
      this.refreshTimeoutReference = setTimeout(() => this.refreshJams(), this.refreshTimeout);
    }
  }

  public withLead(periodIndex: number, jamIndex: number, lead: number): void {
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      lead: Objects.ifNull(lead, -1),
    });
  }

  public withTeam1Jammer(periodIndex: number, jamIndex: number, skater?: Skater): void {
    const skaterId = skater?.id;
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team1JammerId: Objects.ifNull(skaterId, -1),
    });
  }

  public withTeam2Jammer(periodIndex: number, jamIndex: number, skater?: Skater): void {
    const skaterId = skater?.id;
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team2JammerId: Objects.ifNull(skaterId, -1),
    });
  }

  public withBlocker(periodIndex: number, jamIndex: number, team: number, blockerIndex: number, skater?: Skater): void {
    const skaterId = skater?.id;
    let jam = {
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
    } as Partial<Jam>;

    if (team === 0) {
      if (blockerIndex === 0) {
        jam = {...jam, team1PivotId: Objects.ifNull(skaterId, -1)};
      } else if (blockerIndex === 1) {
        jam = {...jam, team1Blocker1Id: Objects.ifNull(skaterId, -1)};
      } else if (blockerIndex === 2) {
        jam = {...jam, team1Blocker2Id: Objects.ifNull(skaterId, -1)};
      } else if (blockerIndex === 3) {
        jam = {...jam, team1Blocker3Id: Objects.ifNull(skaterId, -1)};
      } else {
        return;
      }
    } else if (team === 1) {
      if (blockerIndex === 0) {
        jam = {...jam, team2PivotId: Objects.ifNull(skaterId, -1)};
      } else if (blockerIndex === 1) {
        jam = {...jam, team2Blocker1Id: Objects.ifNull(skaterId, -1)};
      } else if (blockerIndex === 2) {
        jam = {...jam, team2Blocker2Id: Objects.ifNull(skaterId, -1)};
      } else if (blockerIndex === 3) {
        jam = {...jam, team2Blocker3Id: Objects.ifNull(skaterId, -1)};
      } else {
        return;
      }
    } else {
      return;
    }

    this.createOrUpdateJam(jam);
  }

  public withTeam1Line(periodIndex: number, jamIndex: number, teamLine?: TeamLine): void {
    const lineId = teamLine?.id;
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team1LineId: Objects.ifNull(lineId, -1),
    });
  }

  public withTeam2Line(periodIndex: number, jamIndex: number, teamLine?: TeamLine): void {
    const lineId = teamLine?.id;
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team2LineId: Objects.ifNull(lineId, -1),
    });
  }

  public withTeam1StarPass(periodIndex: number, jamIndex: number, checked: boolean): void {
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team1StarPass: checked,
    });
  }

  public withTeam2StarPass(periodIndex: number, jamIndex: number, checked: boolean): void {
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team2StarPass: checked,
    });
  }

  public withTeam1Score(periodIndex: number, jamIndex: number, event: any): void {
    const score = Number(event.target.value);
    if (score < 0) {
      return;
    }
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team1Score: score,
    });
  }

  public withTeam2Score(periodIndex: number, jamIndex: number, event: any): void {
    const score = Number(event.target.value);
    if (score < 0) {
      return;
    }
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team2Score: score,
    });
  }

  public decreaseTeam1Score(periodIndex: number, jamIndex: number): void {
    const state = this.state$.getValue();
    const score = (state.toTeam1Score(periodIndex + 1, jamIndex + 1) || 0) - 1;
    if (score < 0) {
      return;
    }
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team1Score: score,
    });
  }

  public decreaseTeam2Score(periodIndex: number, jamIndex: number): void {
    const state = this.state$.getValue();
    const score = (state.toTeam2Score(periodIndex + 1, jamIndex + 1) || 0) - 1;
    if (score < 0) {
      return;
    }
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team2Score: score,
    });
  }

  public increaseTeam1Score(periodIndex: number, jamIndex: number, amount: number = 1): void {
    const state = this.state$.getValue();
    const score = (state.toTeam1Score(periodIndex + 1, jamIndex + 1) || 0) + amount;
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team1Score: score,
    });
  }

  public increaseTeam2Score(periodIndex: number, jamIndex: number, amount: number = 1): void {
    const state = this.state$.getValue();
    const score = (state.toTeam2Score(periodIndex + 1, jamIndex + 1) || 0) + amount;
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      team2Score: score,
    });
  }

  public withJammerPenaltyCount(periodIndex: number, jamIndex: number, teamIndex: number, count: number): void {
    if (count < 0) {
      return;
    }

    const state = this.state$.getValue();
    if (!state.canEdit) {
      return;
    }

    const jam = state.data.jams.find(j => j.period === periodIndex + 1 && j.jamNumber === jamIndex + 1);
    if (!jam) {
      return;
    }
    const jammerId = state.getJammer(teamIndex, periodIndex, jamIndex)?.id;
    if (!jammerId) {
      return;
    }
    const extraData = JamExtraData.fromJson(jam.extraData)
      .withPenaltyCount(jammerId, count)
      .toJson();
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: periodIndex + 1,
      jamNumber: jamIndex + 1,
      extraData: extraData,
    });
  }

  public withLap(periodIndex: number, jamIndex: number, teamIndex: number, trackSegment: TrackSegment): void {
    const state = this.state$.getValue();
    const jam = state.data.jams.find(j => j.period === periodIndex + 1 && j.jamNumber === jamIndex + 1);
    if (!jam) {
      console.warn(`No jam found`);
      return;
    }
    const jammer = teamIndex === 0
      ? state.data.skaters1.find(skater => skater.id === jam.team1JammerId)
      : state.data.skaters2.find(skater => skater.id === jam.team2JammerId);
    if (!jammer) {
      console.warn(`No jammer found`);
      return;
    }
    const extraData = JamExtraData.fromJson(jam.extraData)
      .withLap({skaterId: jammer.id, segment: trackSegment.segment, lane: trackSegment.lane})
      .toJson();
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: jam.period,
      jamNumber: jam.jamNumber,
      extraData: extraData,
    });
  }

  public undoLap(periodIndex: number, jamIndex: number, teamIndex: number): void {
    const state = this.state$.getValue();
    const jam = state.data.jams.find(j => j.period === periodIndex + 1 && j.jamNumber === jamIndex + 1);
    if (!jam) {
      console.warn(`No jam found`);
      return;
    }
    const jammer = teamIndex === 0
      ? state.data.skaters1.find(skater => skater.id === jam.team1JammerId)
      : state.data.skaters2.find(skater => skater.id === jam.team2JammerId);
    if (!jammer) {
      console.warn(`No jammer found`);
      return;
    }
    const extraData = JamExtraData.fromJson(jam.extraData)
      .deleteLastLap(jammer.id)
      .toJson();
    this.createOrUpdateJam({
      gameId: this.gameId(),
      period: jam.period,
      jamNumber: jam.jamNumber,
      extraData: extraData,
    });
  }

  public withMore(periodIndex: number | undefined, jamIndex: number | undefined): void {
    this.state$.invoke(state => state.withSelection(periodIndex, jamIndex));
  }

  public goToGame(): void {
    RouterTools.goToGame(this.router, this.gameId()!);
  }

  public goToGameStats(): void {
    RouterTools.goToGameStats(this.router, this.gameId()!);
  }

  public goToGames(): void {
    RouterTools.goToGames(this.router);
  }

  skaterRenderer(): (skater: Skater) => SelectInfo {
    return (skater: Skater) => !skater ? SelectInfo.empty() : SelectInfo.of(['' + skater.derbyNumber, skater.derbyName]);
  }

  lineRenderer(): (line: TeamLine) => SelectInfo {
    return (line: TeamLine) => !line ? SelectInfo.empty() : SelectInfo.of([line.displayName]);
  }
}
