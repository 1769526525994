<div class="live-page">

  <div class="card" *ngIf="!(state$ | async)!.showData">
    <div class="list">
      <div class="list-item-secondary">
        <div class="list-text-message">
          No data available
        </div>
      </div>
    </div>
  </div>

  <div class="live-jam" *ngIf="(state$ | async)!.showData">
    <div class="live-jam-score">
      <span class="live-jam-score-value">{{ (state$ | async)!.data.totalScores[0] }}</span>
      <span class="live-jam-score-separator">-</span>
      <span class="live-jam-score-value">{{ (state$ | async)!.data.totalScores[1] }}</span>
    </div>

    <div class="live-jam-jammers" *ngIf="(state$ | async)!.data.currentJamInfo as jamInfo">
      <div class="live-jam-jammer">
        <span [class.text-faded-secondary]="jamInfo.isPredictedJammer1">{{ jamInfo.jammer1?.derbyName }}</span>
        <span class="text-faded-secondary">({{ jamInfo.jammer1?.derbyNumber }})</span>
      </div>
      <div>
        {{ jamInfo.score1 || '0' }} - {{ jamInfo.score2 || '0' }}
      </div>
      <div class="live-jam-jammer">
        <span [class.text-faded-secondary]="jamInfo.isPredictedJammer2">{{ jamInfo.jammer2?.derbyName }}</span>
        <span class="text-faded-secondary">({{ jamInfo.jammer2?.derbyNumber }})</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="(state$ | async)!.showJams">
    <ng-container *ngTemplateOutlet="jams"></ng-container>
  </ng-container>

  <ng-container *ngIf="(state$ | async)!.showSkaterPerformance">
    <div class="live-columns-50-50">
      <div class="live-column">
        <ng-container *ngIf="(state$ | async)!.showJammers">
          <ng-container *ngTemplateOutlet="jammers"></ng-container>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.showLines">
          <ng-container *ngTemplateOutlet="lines"></ng-container>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.showPivots">
          <ng-container *ngTemplateOutlet="pivots"></ng-container>
        </ng-container>
      </div>

      <div class="live-column" *ngIf="!!(state$ | async)!.data.scoreEvolution">
        <ng-container *ngTemplateOutlet="scoreEvolution"></ng-container>

        <ng-container *ngIf="(state$ | async)!.showCombos">
          <ng-container *ngTemplateOutlet="combos"></ng-container>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.showSkaters">
          <ng-container *ngTemplateOutlet="skaters"></ng-container>
        </ng-container>
      </div>
    </div>

    <div class="live-columns-50-50">
      <ng-container *ngIf="(state$ | async)!.showJammerVsJammer">
        <ng-container *ngTemplateOutlet="jammerVsJammer"></ng-container>
      </ng-container>

      <div class="live-column">
        <ng-container *ngIf="(state$ | async)!.showJammerVsLine">
          <ng-container *ngTemplateOutlet="jammerVsLine"></ng-container>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.showJammerVsPivot">
          <ng-container *ngTemplateOutlet="jammerVsPivot"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(state$ | async)!.showEscapes">
    <div class="live-columns-50-50">
      <div class="live-column">
        <ng-container *ngIf="(state$ | async)!.showEscapesPerTeam">
          <ng-container *ngTemplateOutlet="escapesPerTeam"></ng-container>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.showEscapesPerLine">
          <ng-container *ngTemplateOutlet="escapesPerLine"></ng-container>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.showEscapesPerPivot">
          <ng-container *ngTemplateOutlet="escapesPerPivot"></ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="(state$ | async)!.showEscapesPerJammer">
        <ng-container *ngTemplateOutlet="escapesPerJammer"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #jams>
  <div [class.live-columns-50-50]="(state$ | async)!.hasJamsInBothPeriods">
    <ng-container *ngFor="let periodInfos of (state$ | async)!.data.jamInfos; let periodIndex = index">
      <div class="live-card" *ngIf="periodInfos.length > 0">
        <div class="card-header">
          <div class="subtitle">
            <app-icon name="laps"></app-icon>
            Period {{ (periodIndex + 1) }}
          </div>
          <div class="header-actions">
            <div class="subtitle">
              {{ (state$ | async)!.data.periodScores[0][periodIndex] }}
              -
              {{ (state$ | async)!.data.periodScores[1][periodIndex] }}
            </div>
          </div>
        </div>
        <div class="live-list">
          <div class="compare-list-header" style="grid-template-columns: 0.4fr 1fr 1fr 0.6fr;">
            <div class="live-border-bottom"></div>
            <div class="compare-list-row-cell live-border-bottom"
                 [style]="'border-color: ' + (backgroundColors$ | async)![0] + ';'">
              {{  (state$ | async)!.teamName(0)  }}
            </div>
            <div class="compare-list-row-cell live-border-bottom"
                 [style]="'border-color: ' + (backgroundColors$ | async)![1] + ';'">
              {{ (state$ | async)!.teamName(1) }}
            </div>
            <div class="compare-list-row-cell live-border-bottom">Score</div>
          </div>

          <ng-container *ngFor="let info of periodInfos; let last = last">
            <div class="live-list-row"
                 [style]="'grid-template-columns: 0.4fr 1fr 1fr ' + (info.isPredictedScore ? '0.6fr;' : '0.3fr 0.3fr;')">
              <div class="compare-list-row-header">
                {{ info.title }}
              </div>
              <div class="live-list-row-cell-left text-faded-secondary"
                   [class.text-faded-secondary]="!info.isCurrentJammer1"
                   [class.compare-list-row-cell-faded]="info.isPredictedJammer1">
                {{ info.jammer1?.derbyName }}&nbsp;
                <span class="compare-list-row-cell-faded">
                ({{ info.jammer1?.derbyNumber }})
            </span>
              </div>
              <div class="live-list-row-cell-left text-faded-secondary"
                   [class.text-faded-secondary]="!info.isCurrentJammer2"
                   [class.compare-list-row-cell-faded]="info.isPredictedJammer2">
                {{ info.jammer2?.derbyName }}&nbsp;
                <span class="compare-list-row-cell-faded">
                ({{ info.jammer2?.derbyNumber }})
            </span>
              </div>
              <ng-container *ngIf="info.isPredictedScore; else realScore">
                <div class="live-list-row-cell compare-list-row-cell-faded">{{ info.score }}</div>
              </ng-container>
              <ng-template #realScore>
                <div class="live-list-row-cell"
                     [class.text-faded-secondary]="info.lead !== 1"
                     [class.live-list-row-cell-lead]="info.lead === 1">{{ info.score1 }}</div>
                <div class="live-list-row-cell"
                     [class.text-faded-secondary]="info.lead !== 2"
                     [class.live-list-row-cell-lead]="info.lead === 2">{{ info.score2 }}</div>
              </ng-template>
            </div>

            <div *ngIf="!last" class="live-list-separator-faded"></div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #escapesPerTeam>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="groups"></app-icon>
        Escapes per Team
      </div>
    </div>
    <div class="live-list">
      <div class="compare-list-header" style="grid-template-columns: 1fr 1fr;">
        <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
          <div class="compare-list-row-cell">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{  (state$ | async)!.teamName(teamIndex)  }}
          </div>
        </ng-container>
      </div>
      <div class="live-tracks">
        <div *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
          <div class="live-track-container">
            <app-track [trackWidth]="180"
                       [lapInfos]="(state$ | async)!.data.teamLapInfos[teamIndex]"
                       [mainColor]="(backgroundColors$ | async)![teamIndex]"
            ></app-track>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #escapesPerJammer>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="jammer"></app-icon>
        Escapes per Jammer
      </div>
    </div>
    <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
      <div class="live-list">
        <div class="compare-list-header" style="grid-template-columns: 1fr;">
          <div class="compare-list-row-cell">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{  (state$ | async)!.teamName(teamIndex)  }}
          </div>
        </div>

        <div class="live-tracks-per-skater">
          <ng-container
            *ngFor="let info of (state$ | async)!.data.jammerInfos[teamIndex]; let jammerIndex = index">
            <div class="live-track-container">
              <app-track [trackWidth]="150"
                         [lapInfos]="(state$ | async)!.data.jammerLapInfos[teamIndex][jammerIndex]"
                         [mainColor]="(backgroundColors$ | async)![teamIndex]"
              ></app-track>
              <div class="live-track-name">
                {{ info.displayName }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #escapesPerLine>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="block"></app-icon>
        Escapes *against* Line
      </div>
    </div>
    <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
      <div class="live-list" *ngIf="(state$ | async)!.data.lineInfos[teamIndex].length > 0">
        <div class="compare-list-header" style="grid-template-columns: 1fr;">
          <div class="compare-list-row-cell">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{  (state$ | async)!.teamName(teamIndex)  }}
          </div>
        </div>

        <div class="live-tracks-per-skater">
          <ng-container
            *ngFor="let info of (state$ | async)!.data.lineInfos[teamIndex]; let jammerIndex = index">
            <div class="live-track-container">
              <app-track [trackWidth]="150"
                         [lapInfos]="(state$ | async)!.data.lineLapInfos[teamIndex][jammerIndex]"
                         [mainColor]="(backgroundColors$ | async)![1 - teamIndex]"
              ></app-track>
              <div class="live-track-name">
                {{ info.displayName }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #escapesPerPivot>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="pivot"></app-icon>
        Escapes *against* Pivot
      </div>
    </div>
    <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
      <div class="live-list" *ngIf="(state$ | async)!.data.pivotInfos[teamIndex].length > 0">
        <div class="compare-list-header" style="grid-template-columns: 1fr;">
          <div class="compare-list-row-cell">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{  (state$ | async)!.teamName(teamIndex)  }}
          </div>
        </div>

        <div class="live-tracks-per-skater">
          <ng-container
            *ngFor="let info of (state$ | async)!.data.pivotInfos[teamIndex]; let pivotIndex = index">
            <div class="live-track-container">
              <app-track [trackWidth]="150"
                         [lapInfos]="(state$ | async)!.data.pivotLapInfos[teamIndex][pivotIndex]"
                         [mainColor]="(backgroundColors$ | async)![1 - teamIndex]"
              ></app-track>
              <div class="live-track-name">
                {{ info.displayName }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #jammers>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="jammer"></app-icon>
        Jammers
      </div>
    </div>
    <div class="live-list">
      <div class="live-list-header">
        <div class="live-list-row-cell live-border-bottom">Jammer</div>
        <div class="live-list-row-cell live-border-bottom">Jams</div>
        <div class="live-list-row-cell live-border-bottom">Leads</div>
        <div class="live-list-row-cell live-border-bottom">Pnlts</div>
        <div class="live-list-row-cell live-border-bottom">- Score +</div>
        <div class="live-list-row-cell live-border-bottom">Avg</div>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="teamIndex === 1">
          <div class="live-list-separator" *ngIf="(state$ | async)!.data.jammerInfos[teamIndex].length > 0"></div>
        </ng-container>

        <div class="live-list-row" *ngFor="let info of (state$ | async)!.data.jammerInfos[teamIndex]">
          <div class="live-list-row-name live-border-right">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{ info.displayName }}
            <span>&nbsp;({{ info.extraName }})</span>
          </div>
          <div class="live-list-row-cell">{{ info.jamsPlayed }}</div>
          <div class="live-list-row-cell">{{ info.leads }}</div>
          <div class="live-list-row-cell">{{ info.penalties }}</div>
          <div class="live-list-row-score-negative">
            <div class="live-score-bar-negative" [style]="'width: ' + info.scoreMinRel * 100 + '%;'"></div>
            {{ -info.scoreMin }}
          </div>
          <div class="live-list-row-score-positive">
            <div class="live-score-bar-positive" [style]="'width: ' + info.scoreRel * 100 + '%;'"></div>
            {{ info.score }}
          </div>
          <div class="live-list-row-cell">{{ info.scoreAvg }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #lines>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="block"></app-icon>
        Lines
      </div>
    </div>
    <div class="live-list">
      <div class="live-list-header">
        <div class="live-list-row-cell live-border-bottom">Jammer</div>
        <div class="live-list-row-cell live-border-bottom">Jams</div>
        <div class="live-list-row-cell live-border-bottom">Leads</div>
        <div class="live-list-row-cell live-border-bottom">Pnlts</div>
        <div class="live-list-row-cell live-border-bottom">- Score +</div>
        <div class="live-list-row-cell live-border-bottom">Avg</div>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="teamIndex === 1">
          <div class="live-list-separator" *ngIf="(state$ | async)!.data.lineInfos[teamIndex].length > 0"></div>
        </ng-container>

        <div class="live-list-row" *ngFor="let info of (state$ | async)!.data.lineInfos[teamIndex]">
          <div class="live-list-row-name live-border-right">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{ info.displayName }}
          </div>
          <div class="live-list-row-cell">{{ info.jamsPlayed }}</div>
          <div class="live-list-row-cell">{{ info.leads }}</div>
          <div class="live-list-row-cell">{{ info.penalties }}</div>
          <div class="live-list-row-score-negative">
            <div class="live-score-bar-negative" [style]="'width: ' + info.scoreMinRel * 100 + '%;'"></div>
            {{ -info.scoreMin }}
          </div>
          <div class="live-list-row-score-positive">
            <div class="live-score-bar-positive" [style]="'width: ' + info.scoreRel * 100 + '%;'"></div>
            {{ info.score }}
          </div>
          <div class="live-list-row-cell">{{ info.scoreAvg }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #pivots>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="pivot"></app-icon>
        Pivots
      </div>
    </div>
    <div class="live-list">
      <div class="live-list-header">
        <div class="live-list-row-cell live-border-bottom">Line</div>
        <div class="live-list-row-cell live-border-bottom">Jams</div>
        <div class="live-list-row-cell live-border-bottom">Leads</div>
        <div class="live-list-row-cell live-border-bottom">Pnlts</div>
        <div class="live-list-row-cell live-border-bottom">- Score +</div>
        <div class="live-list-row-cell live-border-bottom">Avg</div>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="teamIndex === 1">
          <div class="live-list-separator"
               *ngIf="(state$ | async)!.data.pivotInfos[teamIndex].length > 0"></div>
        </ng-container>

        <div class="live-list-row" *ngFor="let info of (state$ | async)!.data.pivotInfos[teamIndex]">
          <div class="live-list-row-name live-border-right">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{ info.displayName }}
            <span>&nbsp;({{ info.extraName }})</span>
          </div>
          <div class="live-list-row-cell">{{ info.jamsPlayed }}</div>
          <div class="live-list-row-cell">{{ info.leads }}</div>
          <div class="live-list-row-cell">{{ info.penalties }}</div>
          <div class="live-list-row-score-negative">
            <div class="live-score-bar-negative" [style]="'width: ' + info.scoreMinRel * 100 + '%;'"></div>
            {{ -info.scoreMin }}
          </div>
          <div class="live-list-row-score-positive">
            <div class="live-score-bar-positive" [style]="'width: ' + info.scoreRel * 100 + '%;'"></div>
            {{ info.score }}
          </div>
          <div class="live-list-row-cell">{{ info.scoreAvg }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #scoreEvolution>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="score"></app-icon>
        Score
      </div>
    </div>

    <div class="live-list">
      <app-chart [data]="(state$ | async)!.data.scoreEvolution"></app-chart>
    </div>
  </div>
</ng-template>

<ng-template #skaters>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="combos"></app-icon>
        All Skaters
      </div>
    </div>
    <div class="live-list">
      <div class="live-list-header"
           style="grid-template-columns: 1fr 0.3fr 0.3fr 1fr 0.3fr;">
        <div class="live-list-row-cell live-border-bottom">Skater</div>
        <div class="live-list-row-cell live-border-bottom">Jams</div>
        <div class="live-list-row-cell live-border-bottom">Leads</div>
        <div class="live-list-row-cell live-border-bottom">- Score +</div>
        <div class="live-list-row-cell live-border-bottom">Avg</div>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="teamIndex === 1">
          <div class="live-list-separator"
               *ngIf="(state$ | async)!.data.skaterInfos[teamIndex].length > 0"></div>
        </ng-container>

        <div *ngFor="let info of (state$ | async)!.data.skaterInfos[teamIndex]"
             class="live-list-row"
             style="grid-template-columns: 1fr 0.3fr 0.3fr 0.5fr 0.5fr 0.3fr;">
          <div class="live-list-row-name live-border-right">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{ info.displayName }}
            <span>&nbsp;({{ info.extraName }})</span>
          </div>

          <div class="live-list-row-cell">{{ info.jamsPlayed }}</div>
          <div class="live-list-row-cell">{{ info.leads }}</div>
          <div class="live-list-row-score-negative">
            <div class="live-score-bar-negative" [style]="'width: ' + info.scoreMinRel * 100 + '%;'"></div>
            {{ -info.scoreMin }}
          </div>
          <div class="live-list-row-score-positive">
            <div class="live-score-bar-positive" [style]="'width: ' + info.scoreRel * 100 + '%;'"></div>
            {{ info.score }}
          </div>
          <div class="live-list-row-cell">{{ info.scoreAvg }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #combos>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="combos"></app-icon>
        Combos
      </div>
    </div>
    <div class="live-list">
      <div class="live-list-header"
           style="grid-template-columns: 1fr 1fr 0.3fr 0.3fr 1fr 0.3fr;">
        <div class="live-list-row-cell live-border-bottom">Jammer</div>
        <div class="live-list-row-cell live-border-bottom">Line</div>
        <div class="live-list-row-cell live-border-bottom">Jams</div>
        <div class="live-list-row-cell live-border-bottom">Leads</div>
        <div class="live-list-row-cell live-border-bottom">- Score +</div>
        <div class="live-list-row-cell live-border-bottom">Avg</div>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="teamIndex === 1">
          <div class="live-list-separator"
               *ngIf="(state$ | async)!.data.comboInfos[teamIndex].length > 0"></div>
        </ng-container>

        <div *ngFor="let info of (state$ | async)!.data.comboInfos[teamIndex]"
             class="live-list-row"
             style="grid-template-columns: 1fr 1fr 0.3fr 0.3fr 0.5fr 0.5fr 0.3fr;">
          <div class="live-list-row-name">
            <app-icon name="circle_full" class="live-color-icon"
                      [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
            {{ info.jammer.derbyName }}
            <span>&nbsp;({{ info.jammer.derbyNumber }})</span>
          </div>
          <div *ngIf="info.line"
               class="live-list-row-name live-list-row-border-right">
            <app-icon name="circle_full" class="live-color-icon"></app-icon>
            {{ info.line.displayName }}
          </div>
          <div *ngIf="info.pivot"
               class="live-list-row-name live-list-row-border-right">
            <app-icon name="circle_full" class="live-color-icon"></app-icon>
            {{ info.pivot.derbyName }}
          </div>

          <div class="live-list-row-cell">{{ info.jamsPlayed }}</div>
          <div class="live-list-row-cell">{{ info.leads }}</div>
          <div class="live-list-row-score-negative">
            <div class="live-score-bar-negative" [style]="'width: ' + info.scoreMinRel * 100 + '%;'"></div>
            {{ -info.scoreMin }}
          </div>
          <div class="live-list-row-score-positive">
            <div class="live-score-bar-positive" [style]="'width: ' + info.scoreRel * 100 + '%;'"></div>
            {{ info.score }}
          </div>
          <div class="live-list-row-cell">{{ info.scoreAvg }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #jammerVsJammer>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="jammer"></app-icon>
        Jammer vs Jammer
      </div>
    </div>
    <div class="live-list">
      <div class="live-tabs">
        <ng-container *ngFor="let mode of VersusMode.VALUES">
          <div class="live-tab"
               [class.live-tab]="(state$ | async)!.data.jammerVsJammerMode !== mode"
               [class.live-tab-selected]="(state$ | async)!.data.jammerVsJammerMode === mode"
               (click)="withJammerVsJammerMode(mode)">
            {{ mode.displayName }}
          </div>
        </ng-container>
      </div>

      <div class="compare-list-header"
           [style]="'grid-template-columns: repeat(' + ((state$ | async)!.data.jammerInfos[1].length + 1) + ', 1fr);'">
        <div class="compare-list-row-cell"></div>
        <div class="compare-list-row-cell live-border-bottom"
             *ngFor="let info of (state$ | async)!.data.jammerInfos[1]">
          <app-icon name="circle_full" class="live-color-icon"
                    [style]="'color: ' + (backgroundColors$ | async)![1] + ';'"></app-icon>
          {{ info.displayName }}
        </div>
      </div>

      <div class="compare-list-row"
           [style]="'grid-template-columns: repeat(' + ((state$ | async)!.data.jammerInfos[1].length + 1) + ', 1fr);'"
           *ngFor="let info of (state$ | async)!.data.jammerInfos[0]; let i = index">
        <div class="compare-list-row-header live-border-right">
          <app-icon name="circle_full" class="live-color-icon"
                    [style]="'color: ' + (backgroundColors$ | async)![0] + ';'"></app-icon>
          {{ info.displayName }}
        </div>
        <div class="compare-list-row-cell"
             *ngFor="let info of (state$ | async)!.data.jammerInfos[1]; let j = index"
             [class.compare-list-row-cell-faded]="(state$ |async)!.data.jammerVsJammerInfos[i][j].jamsPlayed === 0">
          <ng-container *ngIf="(state$ | async)!.data.jammerVsJammerInfos[i][j] as info">
            <ng-container [ngSwitch]="(state$ | async)!.data.jammerVsJammerMode">
              <ng-container *ngSwitchCase="VersusMode.AVG_SCORE">
                {{ info.scoreAvg }}
              </ng-container>
              <ng-container *ngSwitchCase="VersusMode.SCORE">
                {{ info.score }}
              </ng-container>
              <ng-container *ngSwitchCase="VersusMode.JAMS_PLAYED">
                {{ info.jamsPlayed }}
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #jammerVsLine>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="block"></app-icon>
        Jammer vs Line
      </div>
    </div>
    <div class="live-list">
      <div class="live-tabs">
        <ng-container *ngFor="let mode of VersusMode.VALUES">
          <div class="live-tab"
               [class.live-tab]="(state$ | async)!.data.jammerVsLineMode !== mode"
               [class.live-tab-selected]="(state$ | async)!.data.jammerVsLineMode === mode"
               (click)="withJammerVsLineMode(mode)">
            {{ mode.displayName }}
          </div>
        </ng-container>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="(state$ | async)!.jammerVsLineAvailable(teamIndex)">
          <div class="compare-list-header"
               [style]="'grid-template-columns: repeat(' + ((state$ | async)!.data.lineInfos[1 - teamIndex].length + 1) + ', 1fr);'">
            <div class="compare-list-row-cell"></div>
            <div class="compare-list-row-cell live-border-bottom"
                 *ngFor="let info of (state$ | async)!.data.lineInfos[1 - teamIndex]">
              <app-icon name="circle_full" class="live-color-icon"
                        [style]="'color: ' + (backgroundColors$ | async)![1 - teamIndex] + ';'"></app-icon>
              {{ info.displayName }}
            </div>
          </div>

          <div class="compare-list-row"
               [style]="'grid-template-columns: repeat(' + ((state$ | async)!.data.lineInfos[1 - teamIndex].length + 1) + ', 1fr);'"
               *ngFor="let info of (state$ | async)!.data.jammerInfos[teamIndex]; let i = index">
            <div class="compare-list-row-header live-border-right">
              <app-icon name="circle_full" class="live-color-icon"
                        [style]="'color: ' + (backgroundColors$ | async)![teamIndex] + ';'"></app-icon>
              {{ info.displayName }}
            </div>
            <div class="compare-list-row-cell"
                 *ngFor="let info of (state$ | async)!.data.lineInfos[1 - teamIndex]; let j = index"
                 [class.compare-list-row-cell-faded]="(state$ |async)!.data.jammerVsLineInfos[teamIndex][i][j].jamsPlayed === 0">
              <ng-container *ngIf="(state$ | async)!.data.jammerVsLineInfos[teamIndex][i][j] as info">
                <ng-container [ngSwitch]="(state$ | async)!.data.jammerVsLineMode">
                  <ng-container *ngSwitchCase="VersusMode.AVG_SCORE">
                    {{ info.scoreAvg }}
                  </ng-container>
                  <ng-container *ngSwitchCase="VersusMode.SCORE">
                    {{ info.score }}
                  </ng-container>
                  <ng-container *ngSwitchCase="VersusMode.JAMS_PLAYED">
                    {{ info.jamsPlayed }}
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="teamIndex === 0">
          <div class="live-list-separator"
               *ngIf="(state$ | async)!.jammerVsLineAvailable(1)"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #jammerVsPivot>
  <div class="live-card">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="pivot"></app-icon>
        Jammer vs Pivot
      </div>
    </div>
    <div class="live-list">
      <div class="live-tabs">
        <ng-container *ngFor="let mode of VersusMode.VALUES">
          <div class="live-tab"
               [class.live-tab]="(state$ | async)!.data.jammerVsPivotMode !== mode"
               [class.live-tab-selected]="(state$ | async)!.data.jammerVsPivotMode === mode"
               (click)="withJammerVsPivotMode(mode)">
            {{ mode.displayName }}
          </div>
        </ng-container>
      </div>

      <ng-container *ngFor="let team of (state$ | async)!.data.teams; let teamIndex = index">
        <ng-container *ngIf="(state$ | async)!.jammerVsPivotAvailable(teamIndex)">
          <div class="compare-list-header"
               [style]="'grid-template-columns: repeat(' + ((state$ | async)!.data.pivotInfos[1 - teamIndex].length + 1) + ', 1fr);'">
            <div class="compare-list-row-cell"></div>
            <div class="compare-list-row-cell live-border-bottom"
                 *ngFor="let info of (state$ | async)!.data.pivotInfos[1 - teamIndex]">
              <app-icon name="circle_full" class="live-color-icon"
                        [style]="'color: ' + (backgroundColors$ | async)![1] + ';'"></app-icon>
              {{ info.displayName }}
            </div>
          </div>

          <div class="compare-list-row"
               [style]="'grid-template-columns: repeat(' + ((state$ | async)!.data.pivotInfos[1 - teamIndex].length + 1) + ', 1fr);'"
               *ngFor="let info of (state$ | async)!.data.jammerInfos[teamIndex]; let i = index">
            <div class="compare-list-row-header live-border-right">
              <app-icon name="circle_full" class="live-color-icon"
                        [style]="'color: ' + (backgroundColors$ | async)![0] + ';'"></app-icon>
              {{ info.displayName }}
            </div>
            <div class="compare-list-row-cell"
                 *ngFor="let info of (state$ | async)!.data.pivotInfos[1 - teamIndex]; let j = index"
                 [class.compare-list-row-cell-faded]="(state$ |async)!.data.jammerVsPivotInfos[teamIndex][i][j].jamsPlayed === 0">
              <ng-container *ngIf="(state$ | async)!.data.jammerVsPivotInfos[teamIndex][i][j] as info">
                <ng-container [ngSwitch]="(state$ | async)!.data.jammerVsPivotMode">
                  <ng-container *ngSwitchCase="VersusMode.AVG_SCORE">
                    {{ info.scoreAvg }}
                  </ng-container>
                  <ng-container *ngSwitchCase="VersusMode.SCORE">
                    {{ info.score }}
                  </ng-container>
                  <ng-container *ngSwitchCase="VersusMode.JAMS_PLAYED">
                    {{ info.jamsPlayed }}
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="teamIndex === 0">
          <div class="live-list-separator"
               *ngIf="(state$ | async)!.jammerVsPivotAvailable(1)"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
