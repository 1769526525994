import {Test} from "../../models/learning/domain/test";
import {Game} from "../../models/game";
import {Team} from "../../models/team";
import {Objects} from "../../tools/objects";
import {UserSession} from "../../models/user-session";

export interface HomeData {
  tests: Test[],
  mandatoryTests: Test[],
  otherTests: Test[],
  games: Game[],
  liveGame?: Game,
  upcomingGames: Game[],
  recentGames: Game[],
  teams?: Team[]
  session?: UserSession;
  loading: boolean;
}

export class HomeState {
  readonly data: HomeData;

  constructor(data: HomeData) {
    this.data = data;
  }

  public static create(): HomeState {
    return new HomeState({
      tests: [],
      mandatoryTests: [],
      otherTests: [],
      games: [],
      liveGame: undefined,
      upcomingGames: [],
      recentGames: [],
      teams: [],
      session: undefined,
      loading: true,
    });
  }

  public gameTeam1(gameId: number): Team | undefined {
    const game = this.data.games.find(game => game.id === gameId);
    const team = this.data.teams?.find(team => team.id === game?.teamId1);
    return Objects.ifNull(team?.displayName, 'Team 1');
  }

  public gameTeam2(gameId: number): Team | undefined {
    const game = this.data.games.find(game => game.id === gameId);
    const team = this.data.teams?.find(team => team.id === game?.teamId2);
    return Objects.ifNull(team?.displayName, 'Team 2');
  }

  public refresh(tests: Test[], games: Game[], teams: Team[]): HomeState {
    const mandatoryTests = tests.filter(test => test.isMandatory);
    const liveGame = this.findLiveGame(games);
    const upcomingGames = this.findUpcomingGames(games);
    const recentGames = this.findRecentGames(games);
    return new HomeState({
      ...this.data,
      tests: tests,
      games: games,
      mandatoryTests: mandatoryTests,
      otherTests: tests.filter(test => !test.isMandatory),
      liveGame: liveGame,
      upcomingGames: upcomingGames,
      recentGames: recentGames,
      teams: teams,
      loading: false
    });
  }

  public withLoading(loading: boolean): HomeState {
    return new HomeState({
      ...this.data,
      loading: loading,
    });
  }

  public withSession(session?: UserSession): HomeState {
    return new HomeState({
      ...this.data,
      session: session
    });
  }

  private findLiveGame(games: Game[]): Game | undefined {
    return games.find(game => game.isLive);
  }

  private findUpcomingGames(games: Game[]): Game[] {
    const nowTime = new Date().getTime();
    let result = [];
    let gameTime = undefined;

    // Sort games in ascending order of start date
    games.sort((a, b) => {
      const aStartDate = a.startDate?.getTime() || 0;
      const bStartDate = b.startDate?.getTime() || 0;
      return aStartDate - bStartDate;
    });

    // Find the first 3 games that aren't live
    for (let game of games) {
      gameTime = game.startDate?.getTime();
      if (gameTime && gameTime > nowTime && !game.isLive) {
        result.push(game);
      }
      if (result.length === 3) {
        break;
      }
    }
    return result.reverse();
  }

  private findRecentGames(games: Game[]): Game[] {
    const nowTime = new Date().getTime();
    let result = [];
    let gameTime = undefined;

    // Sort games in descending order of start date
    games.sort((a, b) => {
      const aStartDate = a.startDate?.getTime() || 0;
      const bStartDate = b.startDate?.getTime() || 0;
      return bStartDate - aStartDate;
    });

    // Find the first 3 games before now that aren't live
    for (let game of games) {
      gameTime = game.startDate?.getTime();
      if (gameTime && gameTime < nowTime && !game.isLive) {
        result.push(game);
      }
      if (result.length === 3) {
        break;
      }
    }

    return result;
  }
}
