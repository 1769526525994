import {JamExtraData} from "../models/jam-extra-data";
import {Jam} from "../models/jam";
import {Skater} from "../models/skater";
import {Objects} from "./objects";
import {TeamLine} from "../models/team-line";

export class JamTools {

  public static fromExtraData(extraData: JamExtraData | undefined): string | undefined {
    if (!!extraData && extraData.penalties.length === 0 && extraData.laps.length === 0) {
      extraData = undefined;
    }
    return !extraData ? undefined : JSON.stringify(extraData);
  }

  public static toExtraData(json: string | undefined): JamExtraData {
    return !json ? {penalties: [], laps: []} : JSON.parse(json);
  }

  public static jammerId(jam: Partial<Jam>, teamIndex: number): number | undefined {
    return teamIndex === 0 ? jam.team1JammerId : jam.team2JammerId;
  }

  public static pivotId(jam: Partial<Jam>, teamIndex: number): number | undefined {
    return teamIndex === 0 ? jam.team1PivotId : jam.team2PivotId;
  }

  public static score(jam: Jam, teamIndex: number): number | undefined {
    return teamIndex === 0 ? jam.team1Score : jam.team2Score;
  }

  public static lineId(jam: Jam, teamIndex: number): number | undefined {
    return teamIndex === 0 ? jam.team1LineId : jam.team2LineId;
  }

  public static jamsToSkaterIds(jams: Jam[]): (number | undefined)[] {
    return jams.flatMap(j => [j.team1JammerId, j.team1PivotId, j.team1Blocker1Id, j.team1Blocker2Id, j.team1Blocker3Id, j.team2JammerId, j.team2PivotId, j.team2Blocker1Id, j.team2Blocker2Id, j.team2Blocker3Id]);
  }

  public static jamsToLineIds(jams: Jam[]): (number | undefined)[] {
    return jams.flatMap(j => [j.team1LineId, j.team2LineId]);
  }

  public static filterAndSortSkaters(skaters: Skater[], jams: Jam[]): Skater[] {
    const skaterIds = JamTools.jamsToSkaterIds(jams);
    return skaters.filter(s => s.isActive || skaterIds.includes(s.id)).sort((a, b) => Objects.ifNull(a.derbyNumber, '0').localeCompare(Objects.ifNull(b.derbyNumber, '0')) || a.derbyName.localeCompare(b.derbyName));
  }

  public static filterAndSortLines(lines: TeamLine[], jams: Jam[]): TeamLine[] {
    const lineIds = JamTools.jamsToLineIds(jams);
    return lines.filter(l => l.isActive || lineIds.includes(l.id)).sort((a, b) => a.displayName.localeCompare(b.displayName));
  }
}
