import {Color} from "../models/color";

export class ColorTools {

  private static readonly COLORS: Color[] = [
    {code: 'black', name: 'Black', hex: '#000000'},
    {code: 'blue', name: 'Blue', hex: '#3333ff'},
    {code: 'brown', name: 'Brown', hex: '#aa5500'},
    {code: 'green', name: 'Green', hex: '#55aa00'},
    {code: 'orange', name: 'Orange', hex: '#FFA500'},
    {code: 'pink', name: 'Pink', hex: '#FF77cc'},
    {code: 'purple', name: 'Purple', hex: '#990099'},
    {code: 'red', name: 'Red', hex: '#cc0000'},
    {code: 'white', name: 'White', hex: '#FFFFFF'},
    {code: 'yellow', name: 'Yellow', hex: '#eeee00'},
  ].sort((a, b) => a.name.localeCompare(b.name));

  public static getColors(): Color[] {
    return ColorTools.COLORS;
  }

  public static toHex(color: string | undefined, blackToWhite: boolean = false): string {
    color = blackToWhite && color === 'black' ? 'white' : color;
    const hex = ColorTools.COLORS.find(candidate => candidate.code === color)?.hex;
    return !hex ? ColorTools.toHex('white') : hex;
  }

  public static mixHexColors(color1: string, color2: string, factor: number): string {
    // Clamp factor to the range [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Extract RGB components from the first color
    let r1 = parseInt(color1.substring(1, 3), 16);
    let g1 = parseInt(color1.substring(3, 5), 16);
    let b1 = parseInt(color1.substring(5, 7), 16);

    // Extract RGB components from the second color
    let r2 = parseInt(color2.substring(1, 3), 16);
    let g2 = parseInt(color2.substring(3, 5), 16);
    let b2 = parseInt(color2.substring(5, 7), 16);

    // Calculate the interpolated RGB values
    let r = Math.round(r1 + (r2 - r1) * factor);
    let g = Math.round(g1 + (g2 - g1) * factor);
    let b = Math.round(b1 + (b2 - b1) * factor);

    // Convert each component back to hex and return the combined hex color
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }
}
